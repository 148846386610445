<template>
  <v-dialog
    width="700"
    persistent
    fullscreen
    transition="slide-x-transition"
    v-model="getModalViewInvestment"
  >
    <v-card dark class="expande-horizontal wrap">
      <div class="expande-horizontal px-6 py-3">
        <v-btn dark @click="fechaModalViewInvestment" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte white--text">
          Ativo de Investmento
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fechaModalViewInvestment">
          <v-icon color="white">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Nome
              </span>
              <v-text-field
                v-model="getInvestment.title"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Descrição
              </span>
              <v-text-field
                v-model="getInvestment.description"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                :color="$theme.primary"
                label=""
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Preço
              </span>
              <v-text-field
                v-model="getInvestment.price"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                prefix="$"
                v-mask="['#', '##', '###', '####', '#####', '######']"
                :color="$theme.primary"
                label="ex: 200"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Preço Adicional
              </span>
              <v-text-field
                v-model="getInvestment.unilevel_price"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                prefix="$"
                v-mask="['#', '##', '###', '####', '#####', '######']"
                :color="$theme.primary"
                label="ex: 20"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Teto de rendimento
              </span>
              <v-text-field
                v-model="getInvestment.total_percent_gain"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                prefix="%"
                v-mask="['#', '##', '###', '#####', '######']"
                :color="$theme.primary"
                label="ex: 200"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Rendimento Diário Personalizado
              </span>
              <v-text-field
                v-model="getInvestment.daily_percentage_payment"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                prefix="%"
                hint="Deixe o valor em 0 para não haver rendimento personalizado pelo plano"
                v-mask="['#', '##', '###']"
                :color="$theme.primary"
                label="ex: "
              ></v-text-field>
            </v-flex>

            <v-flex></v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Ações a tomar na aprovação
              </span>
              <v-select
                :items="getSystemActions"
                label="Escolha aqui"
                item-text="description"
                multiple
                return-object
                v-model="getInvestment.system_actions"
              ></v-select>
            </v-flex>

            <v-flex class="px-7 pb-3" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Capa
              </span>
              <SendArchive />
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="validate" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: {
    SendArchive
  },
  computed: {
    ...mapGetters([
      "getModalViewInvestment",
      "getInvestment",
      "getSystemActions"
    ])
  },
  methods: {
    ...mapActions([
      "createInvestment",
      "updateInvestment",
      "fechaModalViewInvestment"
    ]),
    validate() {
      if (this.$refs.form.validate()) {
        if (this.getInvestment._id) {
          this.updateInvestment();
        } else {
          this.createInvestment(this.getInvestment);
        }
      }
    }
  }
};
</script>
